function mountSplides() {
  document.querySelectorAll('.splide.partners').forEach(slider => {
    const splide = new Splide(slider, {
      type: 'loop',
      drag   : true,
      perPage: 4,
      pagination: false,
      gap: '1.5rem',
      breakpoints: {
        1199: {
          perPage: 3
        },
        767: {
          perPage: 1
        },
      }
    })
    splide.mount()
  })

  document.querySelectorAll('.splide.packages').forEach(slider => {
    const splide = new Splide(slider, {
      type: 'loop',
      perPage: 2,
      pagination: false,
      gap: '1.5rem',
      breakpoints: {
        3099: {
          padding: { left: 0, right: '10rem' },
        },
        1199: {
          perPage: 1
        },
        991: {
          padding: { left: 0, right: '0rem' },
        }
      }
    })
    splide.mount()
  })

  document.querySelectorAll('.splide.news').forEach(slider => {
    const splide = new Splide(slider, {
      drag   : true,
      perPage: 3,
      pagination: false,
      gap: '1.5rem',
      breakpoints: {
        1199: {
          perPage: 2
        },
        767: {
          perPage: 1
        },
      },
    })
    splide.mount()
  })

  document.querySelectorAll('.splide.events').forEach(slider => {
    const splide = new Splide(slider, {
      drag   : true,
      perPage: 3,
      pagination: false,
      gap: '1.5rem',
      breakpoints: {
        1199: {
          perPage: 2
        },
        767: {
          perPage: 1
        },
      },
    })
    splide.mount()
  })

  document.querySelectorAll('.splide.testimonials').forEach(slider => {
    const splide = new Splide(slider, {
      drag   : true,
      perPage: 1,
      pagination: true,
      gap: '1.5rem',
    })
    splide.mount()
  })

  document.querySelectorAll('.splide.tour').forEach(slider => {
    const splide = new Splide(slider, {
      drag   : true,
      perPage: 2,
      pagination: false,
      gap: '1.5rem',
      breakpoints: {
        1199: {
          perPage: 1
        },
      },
    })
    splide.mount()
  })
}

mountSplides()
document.documentElement.addEventListener('turbo:load', mountSplides)
